.search-params {
    width: calc(100vw - 255px);
    position: fixed;
    top: 64px;
    left: 216px;
    z-index: 100;
    padding: 20px 30px 0;
    background: #fff;
    .search-template{
        background: #e9f2fa;
        padding: 20px 20px 1px 0px;
    }

}

.list-container {
    padding-top: 205px;
    .ant-table-thead > tr > th {
        font-size: 16px;
        font-weight: bold;
    }
}
.space-height {
    height: 16px;
    width: 100%;
    position: fixed;
    top: 64px;
    left: 200px;
    background: #f0f2f5;
    z-index: 101;
}
@primary-color: #2a82cc;