.table-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    .sum {
        padding-top: 20px;
        span {
            margin-right: 20px;
        }
    }
}
@primary-color: #2a82cc;