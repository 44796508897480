.view_modal {
  .ant-table-content {
    table {
      border-collapse: collapse;
    }
  }
  .ant-table-body {
    table {
      border-collapse: collapse;
    }
  }
  .ant-table-thead > tr > th {
    padding: 5px 10px;
    font-weight: bold;
  }
  .ant-table-tbody > tr > td {
    padding: 5px 10px;
  }
  .ant-table-tbody > tr {
    pointer-events: none !important;
  }
}
.table_flex {
  display: flex;
  // align-items: center;
  justify-content: space-around;
}

.table_record_style {
  background: #ccc;
}
.sum_style {
  background: #d4712d;
  font-weight: 700;
}
.table_title_style {
  .ant-table-thead > tr {
    th {
      padding: 5px 10px;
      font-weight: bold;
      background: #d4712d;
      border: 0.5px solid #666 !important;
    }
    .ant-table-cell-scrollbar {
      box-shadow: none;
    }
  }
  .ant-table-tbody > tr > td {
    border: 1px solid #666 !important;
  }
}

@primary-color: #2a82cc;