.header-container {
    position: fixed;
    top: 0;
    left: 200px;
    // width: 100%;
    width: calc(100vw - 200px);
    background: #fff;
    padding: 0 40px 0 20px;
    z-index: 99;
    .header-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .right-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .user {
                margin-left: 20px;
                img {
                    width: 30px;
                }
                .dropdown-menu {
                    margin-left: 5px;
                    vertical-align: middle;
                    cursor: pointer;
                }
            }
        }
    }
}
@primary-color: #2a82cc;