.progress-container {
    .header {
        display: flex;
        align-items: center;
        justify-content: center;
        span:nth-child(2n-1) {
            width: 30px;
            height: 30px;
            line-height: 30px;
        }
        span:nth-child(2) {
            margin: 0 40px;
        }
        margin-bottom: 10px;
    }
    .content {
        display: flex;
        align-items: center;
        justify-content: space-around;
        .item {
            width: 100%;
            margin: 0 3px;
            text-align: center;
            cursor: pointer;
            span {
                margin-top: 5px;
                font-size: 12px;
            }
            .ant-progress-inner {
                border-radius: 0;
            }
        }
    }
    .footer {
        margin-top: 15px;
        span:nth-child(1) {
            margin-right: 20px;
        }
        ::before {
            content: '';
            width: 20px;
            height: 9px;
            display: inline-block;
            margin-right: 8px;
            border-radius: 1px;
        }
        span:nth-child(1)::before {
            background: #dbdbdb;
        }
        span:nth-child(2)::before {
            background: #1890ff;

        }
        font-size: 12px;
        text-align: center;
    }
}
.details-container {
    display: flex;
    justify-content: space-between;
    .left-charts {
        width: 58%;
        margin: 0 20px 24px 0;
    }
    .right-table {
        width: 42%;
        background: #f8f8f8;
        padding: 10px 0;
        box-sizing: border-box;
        .ant-table {
            background: #f8f8f8;
        }
        .ant-table-thead {
            .ant-table-cell {
                font-weight: bold !important;
            }
        }
        .ant-table-body {
            height: 330px;
        }
    }
}
.tabs-card-container {
    .ant-card-body {
        padding: 0 24px;
    }
}
.picker-year {
    .ant-picker-input {
        input {
    cursor: pointer;
            color: #2a82cc
        }
    }
}
@primary-color: #2a82cc;