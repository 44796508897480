.income-expend {
    display: flex;
    // align-items: center;
    // min-height: 80vh;
    // justify-content: center;
    .item:nth-child(2) {
        margin: 0 24px;
    }
    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        width: 33%;
        height: 180px;
        padding: 20px 30px;
        background: #eee;
        text-align: center;
        border-radius: 3px;
        font-size: 12px;
        // cursor: pointer;
        .title {
            position: relative;
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            font-size: 14px;
            font-weight: bold;
            .action-btn {
                position: absolute;
                right: -10px;
                span {
                    font-weight: 400;
                    color: #2a82cc;
                    cursor: pointer;
                }
            }
        }
        .describe {
            height: 40px;
            margin: 5px 0;
            color: #999;
        }
    }
   
}
@primary-color: #2a82cc;