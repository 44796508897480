.upload-modal {
    .upload-container {
        display: flex;
        flex-direction: column;
        .ant-btn {
            align-self: flex-end;
            margin: 5px 0;
            font-size: 12px;
        }
        .upload-box {
            width: 100%;
            height: 200px;
        }
    }
    .ant-modal-body {
        padding: 0 30px 30px !important;
    }
    .ant-upload-text {
        font-size: 14px;
        .ant-btn {
            padding: 0 5px;
            font-size: 16px;
        }
    }
}
@primary-color: #2a82cc;