.admin-login {
  display: flex;
  justify-content: space-between;
  height: 100%;
  // background: linear-gradient(45deg,#00336e,#0e3c96);
  background: linear-gradient(45deg, #00336e, #2a7bc1);
  .left-wrap {
    width: 100%;
    .title {
      position: absolute;
      top: 50px;
      margin-left: 100px;
      color: #fff;
      font-size: 40px;
    }
    .bg-warp {
      height: calc(100vh - 160px);
      min-height: 400px;
      margin-top: 90px;
      background: url("../../assets/images//login-bg.jpg") no-repeat;
      background-size: cover;
    }
  }
  .right-wrap {
    width: 500px;
    height: 100%;
    position: relative;
    .from-box {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translateY(-50%);
      width: 350px;
      height: 430px;
      padding: 40px 30px 30px;
      box-sizing: border-box;
      background: #fff;
      .login-logo {
        margin-bottom: 35px;
        img {
          width: 160px;
        }
      }
      .login-title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        background: rgb(245, 245, 245);
        border-radius: 15px;
        padding: 2px 10px;
        // todo
        // font-weight: bold;
        position: absolute;
        top: 105px;
        left: 25px;
        width: 85%;
        z-index: 100;
        .dd-logo {
          width: 17px;
          height: 17px;
          margin-right: 10px;
        }
        p {
          margin: 0;
          color: #999;
        }
        span {
          margin: 0 5px;
          color: #5094f3;
        }
      }
      .login-form-button {
        width: 100%;
      }
    }
  }
  #scanCode {
    position: absolute;
    top: 115px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@primary-color: #2a82cc;