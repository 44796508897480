.logo {
    display: flex;
    align-items: center;
    padding: 10px;
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
    img {
        width: 55px;
    }
    .title {
        margin-left: 10px;
        line-height: initial;
        font-size: 12px;
        div:nth-child(1) {
            font-size: 16px;
        }
    }
}
@primary-color: #2a82cc;